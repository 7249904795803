import { useState } from "react";
import { DEFAULT_DISPLAY_SETTING, isVisible } from "../../utils/displaySettingUtil";
import { useGroup } from '../../hooks/use-group';
import houseApi from "../../api/houseApi";

import "./DailyReportDisplaySettingModal.css";

export default function DailyReportDisplaySettingModal({ houseName, houseDisplayName, displaySetting, onClose }) {

  const [setting, setSetting] = useState({ ...DEFAULT_DISPLAY_SETTING, ...displaySetting });
  const { initGroup } = useGroup();

  const handleChangeDisplaySetting = (name, value) => {
    const newSetting = { ...setting }
    newSetting["home"]["dailyReport"]["visible"][name] = value;
    setSetting(newSetting);
  };

  const handleUpdateDisplaySetting = async () => {
    try {
      await houseApi.update(houseName, {
        display_setting: { ...setting }
      });
      await initGroup();
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <div className="daily-report-display-setting-modal-container">
      <div className="daily-report-display-setting-modal-title">
        <p className="daily-report-display-setting-modal-title-text">表示項目の設定（{houseDisplayName}）</p>
        <div
          className="daily-report-display-setting-modal-close-button"
          onClick={onClose}
        >
          <span>×</span>
        </div>
      </div>
      <div className="daily-report-display-setting-modal-table">
        <table>
          <thead>
            <tr>
              <th>選択</th>
              <th>項目名</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("tempDaytimeMean", event.target.checked)}
                  checked={isVisible(setting, "tempDaytimeMean")}
                />
              </td>
              <td>温度日中平均</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("tempNighttimeMean", event.target.checked)}
                  checked={isVisible(setting, "tempNighttimeMean")}
                />
              </td>
              <td>温度夜間平均</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("humDailyMean", event.target.checked)}
                  checked={isVisible(setting, "humDailyMean")}
                />
              </td>
              <td>湿度1日平均</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("vpdDailyMean", event.target.checked)}
                  checked={isVisible(setting, "vpdDailyMean")}
                />
              </td>
              <td>飽差1日平均</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("co2DaytimeMean", event.target.checked)}
                  checked={isVisible(setting, "co2DaytimeMean")}
                />
              </td>
              <td>CO2日中平均</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("solarDailySum", event.target.checked)}
                  checked={isVisible(setting, "solarDailySum")}
                />
              </td>
              <td>日射量1日積算</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("irrigationDailySum", event.target.checked)}
                  checked={isVisible(setting, "irrigationDailySum")}
                />
              </td>
              <td>かん水実績1日積算</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("fuelConsumptionDailySum", event.target.checked)}
                  checked={isVisible(setting, "fuelConsumptionDailySum")}
                />
              </td>
              <td>燃料消費量1日積算</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("transDailySum", event.target.checked)}
                  checked={isVisible(setting, "transDailySum")}
                />
              </td>
              <td>蒸散量1日積算</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("laiDailyMax", event.target.checked)}
                  checked={isVisible(setting, "laiDailyMax")}
                />
              </td>
              <td>LAI最大値</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("carbonDailySum", event.target.checked)}
                  checked={isVisible(setting, "carbonDailySum")}
                />
              </td>
              <td>光合成量1日積算</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("flowersDaytimeMean", event.target.checked)}
                  checked={isVisible(setting, "flowersDaytimeMean")}
                />
              </td>
              <td>開花数日中平均</td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  onChange={(event) => handleChangeDisplaySetting("fruitsDaytimeMean", event.target.checked)}
                  checked={isVisible(setting, "fruitsDaytimeMean")}
                />
              </td>
              <td>着果数日中平均</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="daily-report-display-setting-modal-update-button">
        <button onClick={handleUpdateDisplaySetting}>更新</button>
      </div>
    </div>
  );
};
