import useDailyReportSP2Info from "../../hooks/use-daily-report-sp2-info";
import { displayValue } from "../../utils/displayUtil";
import { mean, getTotalValueOfFruits } from "../../utils/calcUtil";

export default function DailyReportTableFruitsRow({ houseName, latestTimestamp }) {

  const { value: unripeFruitsDaytimeMean7dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 7, "mean", "daytime");
  const { value: unripeFruitsDaytimeMean6dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 6, "mean", "daytime");
  const { value: unripeFruitsDaytimeMean5dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 5, "mean", "daytime");
  const { value: unripeFruitsDaytimeMean4dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 4, "mean", "daytime");
  const { value: unripeFruitsDaytimeMean3dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 3, "mean", "daytime");
  const { value: unripeFruitsDaytimeMean2dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 2, "mean", "daytime");
  const { value: unripeFruitsDaytimeMean1dAgoValue } = useDailyReportSP2Info("unripe_fruits", houseName, latestTimestamp, 1, "mean", "daytime");

  const { value: ripeFruitsDaytimeMean7dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 7, "mean", "daytime");
  const { value: ripeFruitsDaytimeMean6dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 6, "mean", "daytime");
  const { value: ripeFruitsDaytimeMean5dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 5, "mean", "daytime");
  const { value: ripeFruitsDaytimeMean4dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 4, "mean", "daytime");
  const { value: ripeFruitsDaytimeMean3dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 3, "mean", "daytime");
  const { value: ripeFruitsDaytimeMean2dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 2, "mean", "daytime");
  const { value: ripeFruitsDaytimeMean1dAgoValue } = useDailyReportSP2Info("ripe_fruits", houseName, latestTimestamp, 1, "mean", "daytime");

  return (
    <tr>
      <th className="daily-report-fruits-label">着果数</th>
      <th colSpan={2}>日中平均<br />（９〜１５時）</th>
      <td>{displayValue(getTotalValueOfFruits(unripeFruitsDaytimeMean3dAgoValue, ripeFruitsDaytimeMean3dAgoValue))}<br className="sp-br" /> <span className="daily-report-unit">個</span></td>
      <td>{displayValue(getTotalValueOfFruits(unripeFruitsDaytimeMean2dAgoValue, ripeFruitsDaytimeMean2dAgoValue))}<br className="sp-br" /> <span className="daily-report-unit">個</span></td>
      <td>{displayValue(getTotalValueOfFruits(unripeFruitsDaytimeMean1dAgoValue, ripeFruitsDaytimeMean1dAgoValue))}<br className="sp-br" /> <span className="daily-report-unit">個</span></td>
      <td>
        {
          displayValue(mean([
            getTotalValueOfFruits(unripeFruitsDaytimeMean1dAgoValue, ripeFruitsDaytimeMean1dAgoValue),
            getTotalValueOfFruits(unripeFruitsDaytimeMean2dAgoValue, ripeFruitsDaytimeMean2dAgoValue),
            getTotalValueOfFruits(unripeFruitsDaytimeMean3dAgoValue, ripeFruitsDaytimeMean3dAgoValue),
            getTotalValueOfFruits(unripeFruitsDaytimeMean4dAgoValue, ripeFruitsDaytimeMean4dAgoValue),
            getTotalValueOfFruits(unripeFruitsDaytimeMean5dAgoValue, ripeFruitsDaytimeMean5dAgoValue),
            getTotalValueOfFruits(unripeFruitsDaytimeMean6dAgoValue, ripeFruitsDaytimeMean6dAgoValue),
            getTotalValueOfFruits(unripeFruitsDaytimeMean7dAgoValue, ripeFruitsDaytimeMean7dAgoValue),
          ]))
        }<br className="sp-br" /> <span className="daily-report-unit">個</span>
      </td>
    </tr>
  );
}
