export const DEFAULT_DISPLAY_SETTING = {
  "home": {
    "dailyReport": {
      "visible": {
        "tempDaytimeMean": true,
        "tempNighttimeMean": true,
        "humDailyMean": true,
        "vpdDailyMean": true,
        "co2DaytimeMean": true,
        "solarDailySum": true,
        "irrigationDailySum": true,
        "fuelConsumptionDailySum": true,
        "transDailySum": true,
        "laiDailyMax": true,
        "carbonDailySum": true,
        "flowersDaytimeMean": true,
        "fruitsDaytimeMean": true,
      }
    }
  },
  "useCanopy": true
};

export function isVisible(displaySetting, name) {
  if (!displaySetting) return true;
  if (!displaySetting["home"]) return true;
  if (!displaySetting["home"]["dailyReport"]) return true;
  if (!displaySetting["home"]["dailyReport"]["visible"]) return true;
  const visible = displaySetting["home"]["dailyReport"]["visible"][name];
  if (visible === undefined || visible === null) return true;
  return visible;
};

export function useCanopy(displaySetting) {
  if (!displaySetting) return true;
  const _useCanopy = displaySetting["useCanopy"];
  if (_useCanopy === undefined || _useCanopy === null) return true;
  return _useCanopy
};
