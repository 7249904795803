export const convertToVaporPressureDeficit = (airTemperature, relativeHumidity) => {
  if (!Number.isFinite(airTemperature) || !Number.isFinite(relativeHumidity)) return null;

  // 飽和水蒸気圧(hPa)
  const vaporPressureOfWater = 6.1078 * 10 ** (7.5 * airTemperature / (airTemperature + 237.3));

  // 飽和水蒸気量(g/m3)
  const amountOfSaturatedWaterVapor = 217 * vaporPressureOfWater / (airTemperature + 273.15);

  // 飽差(g/m3)
  const vaporPressureDeficit = (100 - relativeHumidity) * amountOfSaturatedWaterVapor / 100;

  return vaporPressureDeficit;
}

export const getTotalValueOfFruits = (unripeFruits, ripeFruits) => {
  if (!Number.isFinite(unripeFruits) && !Number.isFinite(ripeFruits)) return null;
  return unripeFruits + ripeFruits;
};

export const mean = (numbers) => {
  let sum = 0;
  let count = 0;
  for (let i = 0; i < numbers.length; i++) {
    if (typeof numbers[i] === 'number') {
      sum += numbers[i];
      count++;
    }
  }
  if (count === 0) {
    return null;
  }
  return sum / count;
};
