import useDailyReportSP2Info from "../../hooks/use-daily-report-sp2-info";
import { displayValue } from "../../utils/displayUtil";
import { mean } from "../../utils/calcUtil";

export default function DailyReportTableFlowersRow({ houseName, latestTimestamp }) {

  const { value: flowersDaytimeMean7dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 7, "mean", "daytime");
  const { value: flowersDaytimeMean6dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 6, "mean", "daytime");
  const { value: flowersDaytimeMean5dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 5, "mean", "daytime");
  const { value: flowersDaytimeMean4dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 4, "mean", "daytime");
  const { value: flowersDaytimeMean3dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 3, "mean", "daytime");
  const { value: flowersDaytimeMean2dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 2, "mean", "daytime");
  const { value: flowersDaytimeMean1dAgoValue } = useDailyReportSP2Info("flowers", houseName, latestTimestamp, 1, "mean", "daytime");

  return (
    <tr>
      <th className="daily-report-flowers-label">開花数</th>
      <th colSpan={2}>日中平均<br />（９〜１５時）</th>
      <td>{displayValue(flowersDaytimeMean3dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">個</span></td>
      <td>{displayValue(flowersDaytimeMean2dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">個</span></td>
      <td>{displayValue(flowersDaytimeMean1dAgoValue)}<br className="sp-br" /> <span className="daily-report-unit">個</span></td>
      <td>
        {
          displayValue(mean([
            flowersDaytimeMean1dAgoValue,
            flowersDaytimeMean2dAgoValue,
            flowersDaytimeMean3dAgoValue,
            flowersDaytimeMean4dAgoValue,
            flowersDaytimeMean5dAgoValue,
            flowersDaytimeMean6dAgoValue,
            flowersDaytimeMean7dAgoValue,
          ]))
        }<br className="sp-br" /> <span className="daily-report-unit">個</span>
      </td>
    </tr>
  );
}
